.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
  margin-inline-start: 3.5px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  border: solid 2px #0044ef;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  margin-top: 12px;
  top: 0;
  inset-inline-start: 0;
  margin-inline-start: 0;
  background-color: #0044ef;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  top: 12px;
  inset-inline-start: 0;
  margin: 0;
  padding: 12px 0.85px 0px;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border: solid 2px #909aaf;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #0044ef;
  font-weight: 600;
  font-family: 'SFThonburiRegular';
}


.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #3b3b3b;
  font-family: 'SFThonburiRegular';
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #fff;
  border-right: 1px dotted #707070;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #fff;
  border-right: 1px dotted #707070;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-steps .ant-steps-item-tail::after {
  /* display: none; */
  width: 100%;
  height: 1px;
  background: rgba(5, 5, 5, 0.06);
  border-radius: 1px;
  border-right: 1px dotted #707070;
  transition: background 0.3s;
  content: "";
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 12px;
  margin-inline-start: 0;
  background: none;

}

.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 11px;
  height: 11px;
  line-height: 10px;
  background: none;
  margin-inline-start: 65px;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon {
  width: 11px;
  height: 11px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  top: 12px;
  inset-inline-start: 0;
  margin: 0;
  padding: 12px 1.55px 0px;
}