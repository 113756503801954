.container {
  width: 100%;
  height: 270 px;
  padding: 5% 10% 5% 10%;
  border: solid 1px #707070;
  background: url(../../../assets/bg/tracking_mobile_new.png);
  background-repeat: repeat;
  background-size: auto;
  text-align: left;
  position: relative;
}

.inside{  
  position: absolute;
  left: 0;
  top: 0;
  background: black;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.trackingLabel {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.trackingLabelMobile {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.inputSearch {
  height: 56px;
  border-radius: 28px;
  font-size: 20px;
}

.inputSearchMobile {
  height: 40px;
  border-radius: 28px;
  font-size: 14px;
}