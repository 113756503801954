.container {
  width: auto;
  height: 100%;
  background-color: #f4f4f4;
  text-align: center;
}

.labelText {
  margin-top: 1%;
  margin-bottom: 1%;
  color: #909aaf;
  font-size: 16px;
}


.subText {
  margin-top: 8px;
  font-size: 14px;
}

.boxCheckIcon {
  background-color: #0044ef;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.boxIcon {
  background-color: #0044ef;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  padding-left: 0% 3% 0% 3%;

}

.iconAll {
  width: 50px;
  height: 50px;
  /* filter: invert(100%) sepia(13%) saturate(7458%) hue-rotate(181deg) brightness(111%) contrast(98%); */
  filter: brightness(0) invert(1);
}

.iconAllMoblie {
  /* filter: invert(100%) sepia(13%) saturate(7458%) hue-rotate(181deg) brightness(111%) contrast(98%); */
  filter: brightness(0) invert(1);
  width: 32px;
  height: 32px;
}

.iconShow {
  color: #fff;
  font-size: 35px;
}

.iconShowMobile {
  color: #fff;
  font-size: 24px;
  padding: 0% 1% 0% 3%;
}

.leftMargin {
  margin-left: 1%;
}

.trackText {
  font-size: 23px;
  font-weight: 600;
  color: #0044ef;
  text-align: left;
  margin-left: 1%;
}

.trackTextMobile {
  font-size: 16px;
  font-weight: 600;
  color: #0044ef;
  text-align: left;
  padding-left: 16%;
}

.arrowIcon {
  text-align: right;
  margin-left: 3%;
}

.senderName {
  margin-top: -1%;
}

.nameSend {
  font-size: 16px;
  font-weight: 600;
  color: #505050;
  text-align: left;
}

.nameRecipient {
  font-size: 16px;
  font-weight: 600;
  color: #505050;
  text-align: left;
}

.addressSend {
  font-size: 16px;
  font-weight: 600;
  color: #909aaf;
  margin-top: -1%;
}

.stepTitle {
  color: #0044ef;
}

.stepDescription {
  margin-top: -2%;
  color: #909aaf;
  font-size: 12px;
}

.stepDate {
  width: auto;
  height: auto;
  padding-right: 3%;
  text-align: right;
  color: #909aaf;
  font-size: 12px;
}

.stepDateMobile {
  margin-top: -0.5%;
  color: #909aaf;
  font-size: 12px;
}

.stepDotWaitTitle {
  color: #3b3b3b;
}