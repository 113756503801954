.container {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  text-align: center;
  justify-content: center;
  padding: 2% 10% 5% 10%;
}

.containerMobile {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  text-align: center;
  justify-content: center;
  padding: 5% 5% 5% 5%;
}