.site-layout-content {
  min-height: 280px;
  padding: 0px;
}

.whiteheader {
  background-color: #fff;
  padding: 1% 1% 1% 2%;
}

.whiteheaderMobile {
  background-color: #fff;
  padding: 2% 2% 2% 5%;
}

.containerHeader {
  justify-content: space-between;
}

.tracking {
  color: #0044ef;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.signIn {
  color: #1d3557;
  font-weight: 600;
  font-size: 16px;
}

.iconMenu {
  font-size: 20px;
  color: #0044ef;
}