.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

.topFooter {
  color: #fff;
  text-align: left;
  color: #898989;
  font-weight: 600;
  text-align: center;
}

.topFooterText {
  font-size: 18px;
}

.bottomFooter {
  background-color: #0044ef;
  color: #fff;
  text-align: left;
}

.bottomFooterMobile {
  background-color: #0044ef;
  color: #fff;
  text-align: center;
}

.bottomFooterText {
  font-size: 12px;
}


