.searchButton {
  height: 100%;
  border-radius: 28px;
  background-color: #0044ef;
  padding: 16px 75px 14px 77px;
  color: #fff;
  border: none;
  font-size: 22px;
  font-weight: 600;
}

.searchButtonMoblie {
  height: 40%;
  border-radius: 28px;
  background-color: #0044ef;
  padding: 16px 75px 14px 77px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 600;
}